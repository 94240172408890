
import { defineComponent } from 'vue'
import ProductItem from '@/components/ProductItem.vue'
import utilities from "../utilities"

export default defineComponent({
  name: 'Store',
  components: {
    ProductItem
  },
  data() {
    return {
      category: 'todos',
      filter: '',
      products: [] as any[],
      seeds: [] as any[],
      loading: true,
      etiquetas: {} as Record<string, number>
    }
  },
  async created() {
    try {
      const [productos, semillas] = await Promise.all([
        utilities.getProducts('productos'),
        utilities.getProducts('semillas')
      ])

      this.products = productos || []
      this.seeds = semillas || []

      // Procesar etiquetas de semillas
      this.seeds.forEach((seed: any) => {
        if (seed.tags && Array.isArray(seed.tags)) {
          seed.tags.forEach((tag: any) => {
            if (tag.name && tag.id) {
              this.etiquetas[tag.name] = tag.id
            }
          })
        }
      })

    } catch (error) {
      console.error('Error cargando productos:', error)
    } finally {
      this.loading = false
    }
  },
  computed: {
    productsFiltered() {
      if (this.category === 'semillas') {
        if (this.filter) {
          return this.seeds.filter((seed: any) => {
            return seed.tags?.some((tag: any) => tag.name === this.filter)
          })
        }
        return this.seeds
      } else if (this.category === 'productos') {
        return this.products
      }
      return [...this.products, ...this.seeds]
    }
  },
  methods: {
    seleccionarCategoria(categoria: string, filter?: string) {
      this.category = categoria
      this.filter = filter || ''
    },
    addToCart(product: any) {
      // Implementar lógica de agregar al carrito
      console.log('Agregar al carrito:', product)
    },
    viewDetails(product: any) {
      // Implementar lógica de ver detalles
      console.log('Ver detalles:', product)
    }
  }
})
