
import { defineComponent } from "vue";
import Toast from "@/components/Toast.vue";
import '@fortawesome/fontawesome-free/css/all.css';
import utilities from "../utilities";

export default defineComponent({
  name: 'ProductItem',
  components: {
    Toast,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    odd: Boolean
  },
  data() {
    return {
      hover: false,
      validated: false,
    }
  },
  async mounted() {
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = valid;
  },
  computed: {
    showOriginalPrice(): boolean {
      return this.product.discount !== 0 || 
        (this.validated && this.$store.state.flags.descuentoVerificado);
    }
  },
  methods: {
    async addProduct2(product: any) {
      const toast = this.$refs.toast as any;
      if (product.no_stock != null && product.no_stock === 1) {
        toast.show("Lo sentimos, este producto no está disponible");
        return;
      }

      this.$store.commit("addProduct2", product);
      toast.show("Agregaste un producto al carrito");
    },
    formatPrice(price: number) {
      return price?.toLocaleString('es-AR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || '0.00'
    },
    calculateFinalPrice(): number {
      const price = this.product.price;
      const discount = this.product.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      if (discount !== 0) {
        const totalDiscount = this.validated && this.$store.state.flags.descuentoVerificado 
          ? discount + 5 
          : discount;
        return price - (totalDiscount / 100 * price);
      }

      if (verifiedDiscount > 0) {
        return price - (verifiedDiscount / 100 * price);
      }

      return price;
    },
    getDiscount(): number | null {
      const discount = this.product.discount || 0;
      const verifiedDiscount = this.validated && this.$store.state.flags.descuentoVerificado ? 5 : 0;

      if (discount !== 0) {
        return verifiedDiscount > 0 ? discount + verifiedDiscount : discount;
      }

      return verifiedDiscount > 0 ? verifiedDiscount : null;
    }
  }
});
